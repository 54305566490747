import React from 'react'
import { useDispatch } from 'react-redux'
import { AppBarContent } from '@ui/shared'
import { authActions } from '@ui/shared'

import { AppBar as MuiAppBar, Toolbar } from '@material-ui/core'

import { useStyles } from './AppBarStyles'

import { Title } from 'components/Title'

export const HomeAppBar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(authActions.logout())
  }

  return (
    <MuiAppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <img className={classes.logo} src="/images/logo.png" alt="logo" />
        <Title link={true}>PANEL PRACOWNIKA</Title>
        <AppBarContent handlers={{ handleLogout }} />
      </Toolbar>
    </MuiAppBar>
  )
}
