import React, { useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import Table from 'components/table/TableList'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { authHeader } from '@ui/shared'
import { useFetch } from '@ui/shared'

import api from 'api'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
  },
  formControl: {
    marginRight: '16px',
  },
  select: {
    minWidth: '152px',
  },
})

const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}${api.hires}`
const categoriesEndPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/car_rental_places`

const mainData = [
  {
    name: 'KLIENT',
    prop: 'customer.personalDetails.companyName',
  },
  {
    name: 'FLOTA',
    prop: 'hiredItems.length',
  },
  {
    name: 'NAJEM',
    prop: 'startDate',
  },
  {
    name: 'ZWROT',
    prop: 'endDate',
  },

  {
    name: 'STATUS',
    prop: 'hireStatus',
  },
]

export const List = (link) => {
  const classes = useStyles()
  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState({
    search: '',
    page: 1,
  })
  const handleChange = (value) => {
    setQueryParams({
      ...queryParams,
      sourceRentalPlace: value,
    })
  }

  const handleDateChange = (date) => {
    setQueryParams({
      ...queryParams,
      'startDate[after]': date,
    })
  }

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)
  const categoriesRes = useFetch(categoriesEndPoint, options, args)

  return (
    <>
      <div className={classes.grid}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            className={classes.formControl}
            id="date-picker-dialog"
            label="Pokaż od"
            format="DD.MM.YYYY"
            value={queryParams['startDate[after]']}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>

        <FormControl variant="outlined" className={classes.select}>
          <InputLabel>Wybierz</InputLabel>
          <Select
            value={queryParams['sourceRentalPlace']}
            onChange={(e) => handleChange(e.target.value)}
            label="Wybierz"
          >
            <MenuItem value={null}>Wszystkie </MenuItem>
            {categoriesRes.state.data &&
              categoriesRes.state.data.content.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <Table
        data={res.state.data?.content}
        isLoading={res.state.isLoading}
        error={res.state.error}
        mainData={mainData}
        dataLength={res.state.data?.totalItems || 0}
        pagination={true}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        {...link}
      />
    </>
  )
}
