import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { DriveEta, Archive } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
})

export default function BottomNav() {
  const classes = useStyles()
  const history = useHistory()
  const [value, setValue] = useState(history.location.pathname)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    history.push(newValue)
  }

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Bieżące najmy"
        value="/leases/current"
        icon={<DriveEta />}
      />
      <BottomNavigationAction
        label="Zgłoszenia"
        value="/leases/notifications"
        icon={<Archive />}
      />
    </BottomNavigation>
  )
}
