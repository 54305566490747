import React, { useState, useEffect } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Moment from 'react-moment'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { get } from '@ui/shared'
import { authHeader } from '@ui/shared'
import { polishPlural } from '@ui/shared'
import { hireStatus } from 'dataconfig'

const useStyles = makeStyles((theme) => ({
  tableHC: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  tableRC: {
    whiteSpace: 'nowrap',
  },
  tableWrap: {
    overflow: 'auto',
  },
}))

export default function TableList({
  data,
  isLoading,
  error,
  mainData,

  dataLength,
  link,
  pagination,
  queryParams,
  setQueryParams = () => {},
}) {
  const classes = useStyles()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(12)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  function CustomCell(field) {
    const name = Array.isArray(field.field) ? field.field[0] : field.field

    const [data, setData] = useState(null)

    useEffect(() => {
      const fetchData = async (slug) => {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENTRYPOINT}${slug}`,
          {
            method: 'GET',
            headers: {
              accept: 'application/json',
              ...authHeader(),
            },
          }
        )
        const json = await response.json()

        setData(json)
      }
      fetchData(name)
    }, [name])

    return (
      <TableCell>
        {Array.isArray(field.field) && (
          <div>{moment(data?.breakdownDatetime).format('MM.DD.YYYY')}</div>
        )}
      </TableCell>
    )
  }

  return (
    <>
      <div className={classes.tableWrap}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {mainData.map((x, i) => (
                <TableCell key={`thc-${i}`}>
                  <div className={classes.tableHC}>
                    <span>{x.name}</span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading &&
              [...Array(rowsPerPage)].map((i) => (
                <TableRow key={i}>
                  <TableCell colSpan={10}>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRow>
              ))}
            {error && (
              <TableRow>
                <TableCell colSpan={10} style={{ padding: 0 }}>
                  <Alert severity="error">
                    wystąpił błąd podczas pobierania danych
                  </Alert>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              data &&
              data.map((row, index) => {
                return (
                  <React.Fragment key={row.id}>
                    <TableRow>
                      {mainData.map((e, i) => {
                        if (e.prop === mainData[0].prop) {
                          return (
                            <TableCell key={i} className={classes.tableRC}>
                              <Button
                                component={Link}
                                color="primary"
                                to={`${link}/${row.id}`}
                              >
                                {get(row, mainData[0].prop, 'nothing')}
                              </Button>
                            </TableCell>
                          )
                        } else {
                          if (e.prop === 'hiredItems.length') {
                            const car = get(row, e.prop, 'nothing')
                            return (
                              <TableCell className={classes.tableRC}>
                                {car}
                                {polishPlural(
                                  ' pojazd',
                                  ' pojazdy',
                                  ' pojazdów',
                                  car
                                )}
                              </TableCell>
                            )
                          }

                          const customField = get(row, e.prop, 'nothing')
                          if (
                            (typeof customField === 'string' &&
                              customField.charAt(0) === '/') ||
                            Array.isArray(customField)
                          ) {
                            return <CustomCell field={customField} />
                          }

                          if (
                            e.prop === 'startDate' ||
                            e.prop === 'endDate' ||
                            e.prop === 'deadlineDate'
                          ) {
                            return (
                              <TableCell>
                                <Moment format="DD.MM.YYYY">
                                  {get(row, e.prop, 'nothing')}
                                </Moment>
                              </TableCell>
                            )
                          }

                          if (e.name === 'STATUS' && e.prop !== 'accepted') {
                            const statusId = get(row, e.prop, '')
                            const statusTrans = hireStatus.find(
                              (el) => el.id === statusId
                            )
                            return (
                              <TableCell key={i}>
                                {statusTrans ? statusTrans.name : statusId}
                              </TableCell>
                            )
                          }

                          return (
                            <TableCell className={classes.tableRC}>
                              {get(row, e.prop, 'nothing')}
                            </TableCell>
                          )
                        }
                      })}
                    </TableRow>
                  </React.Fragment>
                )
              })}
          </TableBody>
        </Table>
      </div>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[1, 2, 12]}
          labelRowsPerPage="Pokaż:"
          component="div"
          count={dataLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  )
}
