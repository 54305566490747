import React from 'react'
import { Schemable } from '@ui/shared'
import { Loader } from '@ui/shared'
import { useSelector } from 'react-redux'
import { ProfileForm } from './ProfileForm'
import { ChangePasswordForm } from './ChangePasswordForm'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  formControl: {
    padding: '10px',
  },
})
export const ProfileContainer = () => {
  const classes = useStyles()
  const profile = useSelector((state) => state.user?.profile)

  return (
    <Schemable>
      {profile ? (
        <div className={classes.formControl}>
          <ProfileForm resource={profile} />

          <ChangePasswordForm resource={profile} />
        </div>
      ) : (
        <div>
          <Loader align="center" />
        </div>
      )}
    </Schemable>
  )
}
