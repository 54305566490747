import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Title } from 'components/Title'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import CanvasContainer from './canvas/CanvasContainer'
import BreakdownContent from './BreakdownContent'
import { leaseConstants } from '@ui/shared'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  img: {
    maxWidth: '100%',
  },

  root: {
    padding: '10px',
  },
}))
export const Breakdown = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    params: { leaseId },
  } = match

  useEffect(() => {
    dispatch({
      type: leaseConstants.DATA_FETCH_SAGA,
      endpoint: '/car_hire_items',
      id: leaseId,
    })
    return () => {
      dispatch({ type: leaseConstants.DATA_REMOVE_SAGA })
    }
    // eslint-disable-next-line
  }, [])

  const lease = useSelector((state) => state.lease)

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={Object.keys(lease).length === 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {lease.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {Object.keys(lease).length > 0 && (
        <div className={classes.root}>
          <Title>Zgłoszenie</Title>
          {lease.car.photos?.[0]?.url && (
            <img
              className={classes.img}
              src={`${process.env.REACT_APP_API_ENTRYPOINT}${lease.car.photos?.[0]?.url}`}
              alt="car"
            />
          )}
          <Typography variant="h6" gutterBottom>
            Miejsce uszkodzenia
          </Typography>
          <CanvasContainer breakId={lease.breakdowns[0]} />

          <BreakdownContent
            breakId={lease.breakdowns}
            customer={lease.hire.customer}
            car={lease.car ? lease.car : {}}
            item={lease}
          />
        </div>
      )}
    </>
  )
}
