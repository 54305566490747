import React from 'react'
import { prefixPaths } from '@ui/shared'
import { generateRoutes } from '@ui/shared'

import { DriveEta, Archive } from '@material-ui/icons'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { List } from './List'
import { Breakdowns } from './Breakdowns'
import { LeaseContainer } from './Lease'
import { Breakdown } from './Breakdown'

const routes = () => [
  {
    title: 'Bieżące najmy',
    type: Route,
    render: () => <List link="current" />,
    path: '/current',
    exact: true,
    icon: DriveEta,
  },
  {
    title: 'Zgłoszenia',
    type: Route,
    render: () => <Breakdowns link="notifications" />,
    path: '/notifications',
    exact: true,
    icon: Archive,
  },
  {
    title: 'Najem',
    type: Route,
    component: LeaseContainer,
    path: '/current/:leaseId',
    exact: true,
  },
  {
    title: 'Zgłoszenie',
    type: Route,
    component: Breakdown,
    path: '/notifications/:leaseId',
    exact: true,
  },
  {
    title: 'Najmy',
    type: Redirect,
    from: '/',
    to: '/current',
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Leases = (props) => {
  const { match } = props
  return (
    <Page
      routes={generateRoutes(
        prefixPaths(match.url, routes(), ['path', 'from', 'to'])
      )}
    />
  )
}
