import React from 'react'

import { useSelector } from 'react-redux'

import { authHeader } from '@ui/shared'
import { useFetch } from '@ui/shared'

import { get } from '@ui/shared'
import StatusField from './../Lease/fields/StatusField'
import Grid from '@material-ui/core/Grid'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Moment from 'react-moment'
import { makeStyles } from '@material-ui/core/styles'
import { hireStatus } from '../../../dataconfig'
import { Link } from '@material-ui/core'

const useStyles = makeStyles({
  gridParent: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    borderBottom: '1px solid lightgrey',
    padding: '10px',
  },
  gridChild: {
    alignSelf: 'center',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    borderBottom: '1px solid lightgrey',
    padding: '10px',
    marginBottom: '10px',
  },
  img: {
    maxWidth: '100%',
  },
  spacing: {
    marginTop: '10px',
  },
})
export default function BreakdownContent(props) {
  const classes = useStyles()
  const { breakId, customer, car, item } = props
  const auth = useSelector((state) => state.auth)

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token]

  const res = useFetch(
    `${process.env.REACT_APP_API_ENTRYPOINT}${breakId}`,
    options,
    args
  )

  const header = [
    {
      name: 'Czas usterki',
      prop: 'breakdownDatetime',
    },
    {
      name: 'Czas zgłoszenia',
      prop: 'reportDatetime',
    },
    {
      name: 'Opis uszkodzenia',
      prop: 'description',
    },
  ]
  const listStatus = hireStatus.filter((el) => el.type === 'hireItem')
  const val = hireStatus.find((el) => el.id === item.hireItemStatus)
  return (
    <div className={classes.spacing}>
      <div className={classes.gridParent}>
        <div>
          <b>Klient</b>
        </div>
        <div>
          {customer?.personalDetails?.companyName} - {customer?.user?.firstName}{' '}
          {customer?.user?.lastName}
        </div>
      </div>
      <div className={classes.gridParent}>
        <div>
          <b>Pojazd</b>
        </div>
        <div>
          {car?.fullName} ({car?.registrationNumber})
        </div>
      </div>
      {header.map((y, k) => (
        <div className={classes.gridParent} key={`trc-${k}`}>
          <div>
            <b>{y.name}</b>
          </div>
          <div>
            {y.prop === 'breakdownDatetime' || y.prop === 'reportDatetime' ? (
              <Moment format="DD.MM.YYYY">
                {get(res.state.data, y.prop, 'nothing')}
              </Moment>
            ) : (
              get(res.state.data, y.prop, 'nothing')
            )}
          </div>
        </div>
      ))}
      <div className={classes.gridChild}>
        <StatusField
          selectableEntity={
            val !== undefined
              ? val
              : {
                  id: res.state.data.status,
                  name: res.state.data.status,
                  type: 'hireItem',
                }
          }
          listStatus={listStatus}
          leaseId={item.id}
          fieldName={'Status'}
        />
      </div>
      <Typography variant="h6" gutterBottom>
        Pliki najemcy
      </Typography>
      <Grid container spacing={3}>
        {res?.state?.data?.photos?.map((item, i) => {
          return (
            <Grid item key={`img-${i}`}>
              <img
                className={classes.img}
                src={`${process.env.REACT_APP_API_ENTRYPOINT}${item?.url}`}
                alt="background"
              />
            </Grid>
          )
        })}
      </Grid>
      <List>
        {res?.state?.data?.files?.map((item, i) => {
          return (
            <ListItem
              key={`link-${i}`}
              button
              component={Link}
              href={`${process.env.REACT_APP_API_ENTRYPOINT}${item?.url}`}
            >
              <ListItemIcon>
                <FileCopyIcon />
              </ListItemIcon>
              <ListItemText
                primary={item?.url}
                style={{ overflow: 'hidden' }}
              />
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}
