import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { validate } from 'email-validator'
import { authActions } from '@ui/shared'
import { Login } from '@ui/shared'
import { Reset } from './Reset'
import { Box, Typography, Container, CssBaseline } from '@material-ui/core'
import { Copyright } from '@ui/shared'
import { useStyles } from '@ui/shared'
import { useHistory } from 'react-router-dom'

export const LoginContainer = () => {
  const history = useHistory()
  const classes = useStyles()
  const [state, setState] = useState({
    email: '',
    password: '',
    submitted: false,
  })
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((state) => ({ ...state, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setState((state) => ({ ...state, submitted: true }))
    const { email, password } = state

    if (validate(email) && password) {
      dispatch(authActions.login(email, password))
    }
  }

  const isLogging = useSelector((state) => state.auth.isLogging)
  const { email, password, submitted } = state

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.avatar}>
          <img src="/images/logo.png" alt="logo" />
        </div>
        <Typography component="h1" variant="h5">
          PANEL PRACOWNIKA
        </Typography>
        {history.location.pathname.split('/')[1] === 'security' ? (
          <Reset />
        ) : (
          <Login
            values={{ email, password }}
            handlers={{ handleChange, handleSubmit }}
            status={{ submitted, isLogging }}
          />
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}
