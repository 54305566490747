import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Lease from './Lease'
import { Title } from 'components/Title'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { leaseConstants } from '@ui/shared'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  img: {
    width: '312px',
  },
  titleWrap: {
    paddingLeft: '5px',
    paddingTop: '5px',
  },
}))
export const LeaseContainer = ({ match }) => {
  const classes = useStyles()
  const {
    params: { leaseId },
  } = match

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: leaseConstants.DATA_FETCH_SAGA,
      endpoint: '/car_hires',
      id: leaseId,
    })
    return () => {
      dispatch({ type: leaseConstants.DATA_REMOVE_SAGA })
    }
    // eslint-disable-next-line
  }, [])

  const lease = useSelector((state) => state.lease)

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={Object.keys(lease).length === 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {lease.error && (
        <Alert severity="error">wystąpił błąd podczas pobierania danych</Alert>
      )}

      {Object.keys(lease).length > 0 && (
        <>
          <div className={classes.titleWrap}>
            <Title>Najem</Title>
          </div>
          <Lease
            lease={lease}
            header={[
              {
                name: 'ID',
                prop: 'id',
              },
              {
                name: 'KLIENT',
                prop: 'customer.personalDetails.companyName',
              },

              {
                name: 'OD',
                prop: 'startDate',
              },
              {
                name: 'DO',
                prop: 'endDate',
              },
              {
                name: 'WYPOŻYCZALNIA',
                prop: 'sourceRentalPlace.name',
              },
              {
                name: 'STATUS',
                prop: 'hireStatus',
              },
              {
                name: 'POZOSTAŁO',
                prop: 'daysLeft',
                dataProp: 'endDate',
              },
              {
                name: 'WARTOŚĆ',
                prop: 'cost',
              },
              {
                name: 'FAKTURA',
                prop: 'invoice.invoiceStatus',
              },
              {
                name: 'Wystawione dokumenty',
                prop: 'documents',
              },
            ]}
          />
        </>
      )}
    </>
  )
}
